<template>
  <div class="admin-view-container system-view" v-loading="loading">
    <div class="admin-table-search" style="font-size: 20px">会员服务补偿申请设置</div>
    <div class="admin-table-list" v-loading="loading">
      <el-form ref="postForm" label-width="auto" :model="postData" @submit.native.prevent="submitHandler" :rules="rules">
        <el-form-item label="会员服务补偿申请开始" prop="start">
          <el-input v-model="postData.start">
            <span slot="append">日</span>
          </el-input>
        </el-form-item>
        <el-form-item label="会员服务补偿申请结束" prop="end">
          <el-input v-model="postData.end">
            <span slot="append">日</span>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: auto; text-align: center">
          <el-button size="medium" type="primary" :loading="loading" native-type="submit" class="admin-btn">
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {sysConfigGetCompensation, sysConfigUpdCompensation} from '../../api/index';
export default {
  data() {
    return {
      loading: false,
      rules: {
        start: [this.$rules.required],
        end: [this.$rules.required],
      },
      postData: {
        start: '',
        end: '',
      },
    };
  },
  created() {
    this.loading = true;
    sysConfigGetCompensation()
      .then((res) => {
        if (res.data.code == 0) {
          let d = res.data.data.sysConfigValue.split('-');
          this.postData.start = d[0];
          this.postData.end = d[1];
        } else {
          this.$message.error(res.data.msg);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    submitHandler() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let postData = {
            sysConfigValue: [this.postData.start, this.postData.end].join('-'),
          };
          sysConfigUpdCompensation(postData).then((res) => {
            this.loading = false;
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          this.$message.error('请填写完整信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.system-view {
  .el-form {
    margin: 0 auto;
  }
}
</style>
